import './Footer.css'
import React, {useState, useEffect} from 'react'

import {useAuthContext} from '../../../../../contexts/AuthContext'

import {getFooter} from '../../../../../services/ApiClient'

function Footer() {

    const [footerData, setFooterData] = useState([])
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getFooterData = await getFooter()
            setFooterData(getFooterData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className='BiocontrolledInfo-Footer--wrapper'>
            <div className="container-fluid BiocontrolledInfo-Footer">
                <video poster="https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fbiocontrolled-video-footer-poster.jpg?alt=media&token=60ab967f-363a-423c-b51d-9e36edfe2244" loop muted autoPlay playsInline>
                    <source src="https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/videos%2Fbiocontrolled-video-footer.mp4?alt=media&token=683be1a9-8da3-4760-8ac7-633f85ad9618" type="video/mp4" />
                </video>
            </div>
            <div className='row BiocontrolledInfo-Footer--info'>
                <div className='col-12 col-sm-7'>
                    <div className='row'>
                        <div className='col-sm-6 col-12 card'>
                            <div className='card-body'>
                                <span className='card-title'>{language === 'ES' ? footerData[0]?.title : footerData[0]?.title_eng}</span>
                                <p dangerouslySetInnerHTML={{__html: language === 'ES' ? footerData[0]?.description : footerData[0]?.description_eng}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 col-12'>
                            <div className='row' style={{gap: '16px'}}>
                                {footerData.slice(1).map(el =>
                                    <div className='col-12 card'>
                                        <div className='card-body'>
                                            <span className='card-title'>{language === 'ES' ? el?.title : el?.title_eng}</span>
                                            <p dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.description : el?.description_eng}}></p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer
