import axios from "axios"

const http = axios.create({
    //baseURL: process.env.REACT_APP_API_URL || "http://localhost:3000",
    baseURL: "https://grupoleti-api.herokuapp.com/",
    withCredentials: true,
})


http.interceptors.response.use(
    function (response) {
        return response.data
    },
    function (error) {
        if (error.response?.status === 401) {
            localStorage.clear()
            window.location.assign("/login")
        }

        return Promise.reject(error)
    }
)


//admin routes
export const login = ({email, password}) =>
    http.post("/login", {email, password})
export const logOut = () => http.post("/logout")

//admin edit routes inicio
export const getUsInfo = () => http.get("/usinfodata")
export const getUnidades = () => http.get("/unidadesiniciodata")
export const updateTitleUnidadesNegocio = ({id, mainTitle, mainTitle_eng}) => http.patch("/updateunidadesnegociotitle", {id, mainTitle, mainTitle_eng})
export const getPortfolio = () => http.get("/portfolioiniciodata")
export const getFarmaco = () => http.get("/farmacoiniciodata")
export const getModalFarmaco = () => http.get("/modalfarmacoiniciodata")
export const getModalWork = () => http.get("/modalwork")
export const updateModalFarmaco = ({title, subTitle, description, title_eng, subTitle_eng, description_eng, id}) => http.patch("/updatemodalfarmaco", {title, subTitle, description, title_eng, subTitle_eng, description_eng, id})
export const getVadevecumData = () => http.get("/vadevecumdata")
export const updateUsInfoData = ({description, url, buttonTitle, age, age_img, description_eng, url_eng, buttonTitle_eng, id}) => http.patch("/updateusinfodata", {description, url, buttonTitle, age, age_img, description_eng, url_eng, buttonTitle_eng, id})
export const updateUnidadesData = ({logo, desc, url, desc_eng, id}) => http.patch("/updateunidadesiniciodata", {logo, desc, url, desc_eng, id})
export const updatePortfolioData = ({title, description, title_eng, description_eng, id}) => http.patch("/updateportfolioiniciodata", {title, description, title_eng, description_eng, id})
export const createPortfolio = ({superiorTitle, title, description, superiorTitle_eng, title_eng, description_eng}) => http.post("/createportfolioiniciodata", {superiorTitle, title, description, superiorTitle_eng, title_eng, description_eng})
export const updateTitlePortfolio = ({id, superiorTitle, superiorTitle_eng}) => http.patch("/updatetitleportfolioiniciodata", {id, superiorTitle, superiorTitle_eng})
export const updateFarmacoData = ({title, subTitle, buttonTitle, title_eng, subTitle_eng, buttonTitle_eng, id}) => http.patch("/updatefarmacoiniciodata", {title, subTitle, buttonTitle, title_eng, subTitle_eng, buttonTitle_eng, id})
export const deleteCarItem = (id) => http.get(`/deleteportfolioitem/${id}`)
export const deleteUnitItem = (id) => http.get(`/deleteunititem/${id}`)
export const getApiVideo = () => http.get("/videoiniciodata")
export const getApiHomeBanner = () => http.get("/homebannerdata")
export const updateVideoData = (url, id) => http.patch("/updatevideodata", {url, id})
export const updateApiHomeBanner = (url, id) => http.patch("/updatehomebannerdata", {url, id})
export const updateVideoDataEng = (url_eng, id) => http.patch("/updatevideodataeng", {url_eng, id})
export const getHomeBottom = () => http.get("/homebottomdata")
export const updateBottomHome = ({title, img, button, url, title_eng, button_eng, url_eng, id}) => http.patch("/updatebottomhome", {title, img, button, url, title_eng, button_eng, url_eng, id})
export const addHomeScreen = (show_in_home, id) => http.post(`/addprodtohomescreen/${id}`, {show_in_home, id})
export const getCookieInfo = () => http.get("/cookieinfo")
export const updateCookieInfo = ({info, info_eng, id}) => http.patch("/updatecookieinfo", {info, info_eng, id})
export const getRrssInfo = () => http.get("/rrssinfo")
export const updateRrssInfo = ({facebook, linkedin, instagram, id}) => http.patch("/updaterrssinfo", {facebook, linkedin, instagram, id})

//admin edit routes about us

export const getTimeLine = () => http.get("/timelineaboutusdata")
export const getBanner = () => http.get("/bannerdata")
export const getMarcandoPauta = () => http.get("/marcandopautadata")
export const getMegat = () => http.get("/megatdata")
export const getScience = () => http.get("/sciencedata")
export const getGallery = () => http.get("/galleryaboutusdata")
export const deleteGalleryItem = (id) => http.get(`/deletegalleryitem/${id}`)
export const addTimeLineData = ({year, imgURL, desc, desc_eng}) => http.post("/addtimelineaboutusdata", {year, imgURL, desc, desc_eng})
export const addGalleryData = ({mainTitle, title, imgPath, desc, mainTitle_eng, title_eng, desc_eng}) => http.post("/addgalleryaboutusdata", {mainTitle, title, imgPath, desc, mainTitle_eng, title_eng, desc_eng})
export const updateGalleryTitle = ({id, mainTitle, mainTitle_eng}) => http.patch("/updategallerytitle", {id, mainTitle, mainTitle_eng})
export const updateGalleryData = ({desc, imgPath, title, desc_eng, title_eng, id}) => http.patch("/updategalleryaboutus", {desc, imgPath, title, desc_eng, title_eng, id})
export const updateBannerData = ({description, imgURL, description_eng, id}) => http.patch("/updatebannerdata", {description, imgURL, description_eng, id})
export const updateTimelineAboutUs = ({year, desc, imgURL, desc_eng, id}) => http.patch(`/updatetimelineaboutus`, {year, desc, imgURL, desc_eng, id})
export const deleteTimeline = (id) => http.get(`/deletetimelineaboutus/${id}`)
export const updateMarcandoPautaData = ({description, imgURL, description_eng, id}) => http.patch("/updatemarcandopautadata", {description, imgURL, description_eng, id})
export const updateMegatData = ({title, description, url, buttonTitle, logoURL, title_eng, description_eng, url_eng, buttonTitle_eng, id}) => http.patch("/updatemegatdata", {title, description, url, buttonTitle, logoURL, title_eng, description_eng, url_eng, buttonTitle_eng, id})
export const updateScienceData = ({title, desc, imgURL, title_eng, desc_eng, id}) => http.patch("/updatesciencedata", {title, desc, imgURL, title_eng, desc_eng, id})

//admin edit routes our companies

export const getBannerOC = () => http.get("/bannerdataoc")
export const updateBannerDataOC = ({description, description_eng, imgURL, id}) => http.patch("/updatebannerdataoc", {description, description_eng, imgURL, id})
export const getOurCompaniesOC = () => http.get("/ourcompaniesoc")
export const updateOurCompaniesOC = ({name, logo, info, url, name_eng, info_eng, url_eng, id}) => http.patch("/updateourcompaniesoc", {name, logo, info, url, name_eng, info_eng, url_eng, id})
export const deleteOCNegocio = (id) => http.get(`/deleteocnegocio/${id}`)
export const getBannerProductsOC = () => http.get("/bannerproductsoc")
export const updateBannerProductsOC = ({description, description2, imgURL, img2URL, img3URL, description_eng, description2_eng, id}) => http.patch("/updatebannerproductsoc", {description, description2, imgURL, img2URL, img3URL, description_eng, description2_eng, id})
export const getInnovationOC = () => http.get("/innovationoc")
export const updateInnovationOC = ({description, imgURL, description_eng, id}) => http.patch("/updateinnovationoc", {description, description_eng, imgURL, id})
export const getCareOC = () => http.get("/careoc")
export const updateCareOC = ({description, imgURL, description_eng, id}) => http.patch("/updatecareoc", {description, imgURL, description_eng, id})
export const getBottomOC = () => http.get("/bottomoc")
export const updateBottomOC = ({title, url, button, img, title_eng, url_eng, button_eng, id}) => http.patch("/updatebottomoc", {title, url, button, img, title_eng, url_eng, button_eng, id})

//admin edit routes our companies Leti

export const getBannerOCLeti = () => http.get("/bannerdataocleti")
export const updateBannerDataOCLeti = ({description, imgURL, logoURL, description_eng, id}) => http.patch("/updatebannerdataocleti", {description, imgURL, logoURL, description_eng, id})
export const getOurCompaniesInfoCardsLeti = () => http.get("/ourcompaniesinfocardsleti")
export const updateOurCompaniesInfoCardsLeti = ({title, info, title_eng, info_eng, id}) => http.patch("/updateourcompaniesinfocardsleti", {title, info, title_eng, info_eng, id})
export const deleteLetiInfoCard = (id) => http.get(`/deleteletiinfocard/${id}`)
export const createLetiInfoCard = ({title, info, title_eng, info_eng}) => http.post("/createletiinfocard", {title, info, title_eng, info_eng})
export const getTimeLineLeti = () => http.get("/octimelineleti")
export const addTimeLineLetiData = ({desc, url, imgURL, button, desc_eng, url_eng, button_eng}) => http.post("/addoctimelineleti", {desc, url, imgURL, button, desc_eng, url_eng, button_eng})
export const updateTimeLineLetiData = ({desc, url, imgURL, button, desc_eng, url_eng, button_eng, id}) => http.patch("/updateoctimelineleti", {desc, url, imgURL, button, desc_eng, url_eng, button_eng, id})
export const deleteTimelineLeti = (id) => http.get(`/deleteoctimelineleti/${id}`)
export const getEquipoLetiOC = () => http.get("/ocequipoleti")
export const updateEquipoLetiOC = ({description, imgURL, buttonTitle, buttonLink, description_eng, buttonTitle_eng, buttonLink_eng, id}) => http.patch("/updateocequipoleti", {description, imgURL, buttonTitle, buttonLink, description_eng, buttonTitle_eng, buttonLink_eng, id})

//admin edit routes our companies Biocontrolled

export const getBannerOCBiocontrolled = () => http.get("/bannerdataocbiocontrolled")
export const updateBannerDataOCBiocontrolled = ({description, imgURL, logoURL, description_eng, id}) => http.patch("/updatebannerdataocbiocontrolled", {description, imgURL, logoURL, description_eng, id})
export const getOurCompaniesInfoCardsBiocontrolled = () => http.get("/ourcompaniesinfocardsbiocontrolled")
export const updateOurCompaniesInfoCardsBiocontrolled = ({title, info, title_eng, info_eng, id}) => http.patch("/updateourcompaniesinfocardsbiocontrolled", {title, info, title_eng, info_eng, id})
export const deleteBiocontrolledInfoCard = (id) => http.get(`/deletebiocontrolledinfocard/${id}`)
export const createBiocontrolledInfoCard = ({title, info, title_eng, info_eng}) => http.post("/createbiocontrolledinfocard", {title, info, title_eng, info_eng})
export const getTimeLineBiocontrolled = () => http.get("/octimelinebiocontrolled")
export const addTimeLineBiocontrolledData = ({desc, imgURL, buttonTitle, buttonLink, desc_eng, buttonTitle_eng, buttonLink_eng, id}) => http.post("/addoctimelinebiocontrolled", {desc, imgURL, buttonTitle, buttonLink, desc_eng, buttonTitle_eng, buttonLink_eng, id})
export const updateTimeLineBiocontrolledData = ({desc, imgURL, buttonTitle, buttonLink, desc_eng, buttonTitle_eng, buttonLink_eng, id}) => http.patch("/updateoctimelinebiocontrolled", {desc, imgURL, buttonTitle, buttonLink, desc_eng, buttonTitle_eng, buttonLink_eng, id})
export const deleteTLBioData = (id) => http.get(`/deleteoctimelinebiocontrolled/${id}`)
export const getCarrouselBiocontrolled = () => http.get("/occarrouselbiocontrolled")
export const updateCarrouselBiocontrolledData = ({info, info_eng, id}) => http.patch("/updateoccarrouselbiocontrolled", {info, info_eng, id})
export const updateBioCarrouselTitle = ({id, title, title_eng}) => http.patch("/updatebiocarrousletitle", {id, title, title_eng})
export const deleteCarrouselItem = (id) => http.get(`/deletebiocarrouselitem/${id}`)
export const addCarrouselBiocontrolledData = ({title, info, title_eng, info_eng, id}) => http.post("/addoccarrouselbiocontrolled", {title, info, title_eng, info_eng, id})
export const getEquipoBiocontrolledOC = () => http.get("/ocequipobiocontrolled")
export const updateEquipoBiocontrolledOC = ({description, imgURL, buttonTitle, buttonLink, description_eng, buttonTitle_eng, buttonLink_eng, id}) => http.patch("/updateocequipobiocontrolled", {description, imgURL, buttonTitle, buttonLink, description_eng, buttonTitle_eng, buttonLink_eng, id})

//admin edit routes our companies Genven

export const getBannerOCGenven = () => http.get("/bannerdataocgenven")
export const updateBannerDataOCGenven = ({description, imgURL, logoURL, description_eng, logoURL_eng, id}) => http.patch("/updatebannerdataocgenven", {description, imgURL, logoURL, description_eng, logoURL_eng, id})
export const getOurCompaniesVideoGenven = () => http.get("/ourcompaniesvideogenven")
export const updateOurCompaniesVideoGenven = (videoURL, id) => http.patch("/updateourcompaniesvideogenven", {videoURL, id})
export const getTimeLineGenven = () => http.get("/octimelinegenven")
export const updateGenvenTimeline = ({desc, buttonLink, imgURL, buttonText, desc_eng, buttonLink_eng, buttonText_eng, id}) => http.patch("/updategenventimeline", {desc, buttonLink, imgURL, buttonText, desc_eng, buttonLink_eng, buttonText_eng, id})
export const deleteTimelineGenven = (id) => http.get(`/deleteoctimelinegenven/${id}`)
export const addTimeLineGenvenData = ({desc, imgURL, buttonText, buttonLink, desc_eng, buttonText_eng, buttonLink_eng}) => http.post("/addoctimelinegenven", {desc, imgURL, buttonText, buttonLink, desc_eng, buttonText_eng, buttonLink_eng})
export const getProductosGenvenOC = () => http.get("/ocproductosgenven")
export const updateProductosGenvenOC = ({description, buttonTitle, buttonLink, img1URL, img2URL, img3URL, description_eng, buttonTitle_eng, buttonLink_eng, id}) => http.patch("/updateocproductosgenven", {description, buttonTitle, buttonLink, img1URL, img2URL, img3URL, description_eng, buttonTitle_eng, buttonLink_eng, id})
export const addProductosGenvenOC = ({description, buttonTitle, buttonLink, img1URL, img2URL, img3URL, description_eng, buttonTitle_eng, buttonLink_eng}) => http.post("/addocproductosgenven", {description, buttonTitle, buttonLink, img1URL, img2URL, img3URL, description_eng, buttonTitle_eng, buttonLink_eng})
export const getEquipoGenvenOC = () => http.get("/ocequipogenven")
export const updateEquipoGenvenOC = ({description, imgURL, buttonTitle, buttonLink, description_eng, buttonTitle_eng, buttonLink_eng, id}) => http.patch("/updateocequipogenven", {description, imgURL, buttonTitle, buttonLink, description_eng, buttonTitle_eng, buttonLink_eng, id})

//farmaco vigilancia routes
export const getFarmVigData = () => http.get("/farmvigdata")
export const vigilanciaForm = ({name, lastname, sex, medicine, date, effects, prescribed, email, phone}) => http.post("/vigilancia", {name, lastname, sex, medicine, date, effects, prescribed, email, phone})
export const dropVigCard = (id) => http.get(`/vigilancia/${id}/delete`)

//content routes
export const createContent = ({content, url, name, type, content_eng, url_eng, name_eng, type_eng}) => http.post("/update-content", {content, url, name, type, content_eng, url_eng, name_eng, type_eng})

//admin I+D routes
export const getBannerID = () => http.get("/bannerdataid")
export const updateBannerID = ({title, description, imgURL, title_eng, description_eng, id}) => http.patch("/updatebannerdataid", {title, description, imgURL, title_eng, description_eng, id})
export const getInfoCardsIdData = () => http.get("/idinfocards")
export const updateInfoCardsIdData = ({title, picPath, info, btn, title_eng, info_eng, btn_eng, id}) => http.patch("/updateidinfocards", {title, picPath, info, btn, title_eng, info_eng, btn_eng, id})
export const getGoalsIdData = () => http.get("/idgoals")
export const updateGoalsIdData = ({name, title, desc, name_eng, title_eng, desc_eng, id}) => http.patch("/updateidgoals", {name, title, desc, name_eng, title_eng, desc_eng, id})
export const updateGoalsTitle = ({id, title, title_eng}) => http.patch("/updateidgoalstitle", {id, title, title_eng})
export const deleteIDGoals = (id) => http.get(`/deleteidgoals/${id}`)
export const createIDGoal = ({title, name, desc, title_eng, name_eng, desc_eng}) => http.post("/createidgoal", {title, name, desc, title_eng, name_eng, desc_eng})
export const getBottomIdData = () => http.get("/idbottom")
export const updateBottomId = ({title, img, buttonLink, buttonTitle, title_eng, buttonLink_eng, buttonTitle_eng, id}) => http.patch("/updateidbottom", {title, img, buttonLink, buttonTitle, title_eng, buttonLink_eng, buttonTitle_eng, id})
export const getIDInfoBanner = () => http.get("/getidinfobanner")
export const updateInfoBannerIAD = ({backgroundURL, item, backgroundURL_eng, item_eng, id}) => http.patch("/updateinfobannerdataiad", {backgroundURL, item, backgroundURL_eng, item_eng, id})
export const updateInfoBannerIADDet = ({whole, desc, unity, iconURL, number, whole_eng, desc_eng, unity_eng, iconURL_eng, number_eng, id}) => http.patch("/updateinfobannerdataiaddet", {whole, desc, unity, iconURL, number, whole_eng, desc_eng, unity_eng, iconURL_eng, number_eng, id})


//admin I+D tech routes
export const getBannerTech = () => http.get("/bannertech")
export const updateBannerTech = ({title, description, imgURL, title_eng, description_eng, id}) => http.patch("/updatebannertech", {title, description, imgURL, title_eng, description_eng, id})
export const getVideoTech = () => http.get("/videotech")
export const updateVideoTech = ({title, videoURL, title_eng, id}) => http.patch("/updatevideodatatech", {title, videoURL, title_eng, id})
export const getCarouselTech = () => http.get("/carrouseltech")
export const updateCarouselTitleTech = ({id, mainTitle, mainTitle_eng}) => http.patch("/updatetechcarrouseltit", {id, mainTitle, mainTitle_eng})
export const updateCarouselTech = ({title, imgURL, description, mainTitle, title_eng, description_eng, mainTitle_eng, id}) => http.patch("/updatecarrouseltech", {title, imgURL, description, mainTitle, title_eng, description_eng, mainTitle_eng, id})
export const createTechCarousel = ({title, imgURL, description, mainTitle, title_eng, description_eng, mainTitle_eng}) => http.post("/createtechcarrousel", {title, imgURL, description, mainTitle, title_eng, description_eng, mainTitle_eng})
export const deleteCarouselTech = (id) => http.get(`/carrouseltech/${id}/delete`)
export const getMapTech = () => http.get("/maptech")
export const updateMapTech = ({description, mapURL, description_eng, id}) => http.patch("/updatemapdatatech", {description, mapURL, description_eng, id})
export const getBottomTech = () => http.get("/bottomtech")
export const updateBottomTechData = ({title, img, buttonLink, buttonTitle, title_eng, buttonLink_eng, buttonTitle_eng, id}) => http.patch("/updatebottomtech", {title, img, buttonLink, buttonTitle, title_eng, buttonLink_eng, buttonTitle_eng, id})

//admin I+D manufacture routes
export const getBannerManufacture = () => http.get("/bannermanufacture")
export const updateBannerManufacture = ({title, description, imgURL, title_eng, description_eng, id}) => http.patch("/updatebannermanufacture", {title, description, imgURL, title_eng, description_eng, id})
export const updateTitleProccess = ({id, title, title_eng}) => http.patch("/updatetitleproccess", {id, title, title_eng})
export const getCarouselManufacture = () => http.get("/carrouselmanufacture")
export const updateCarouselManufacture = ({title, info, title_eng, info_eng, id}) => http.patch("/updatecarrouselmanufacture", {title, info, title_eng, info_eng, id})
export const createProccess = ({title, info, title_eng, info_eng}) => http.post("/createproccess", {title, info, title_eng, info_eng})
export const deleteProccess = (id) => http.get(`/deleteproccess/${id}`)
export const updateCertificatesImage = ({imgURL, id}) => http.patch("/updatecertificatesimage", {imgURL, id})
export const deleteCertificate = (id) => http.get(`/deletecertificate/${id}`)
export const getCertificatesManufacture = () => http.get("/certificatesmanufacture")
export const updateCertificatesManufactureData = ({title, desc, title_eng, desc_eng}) => http.post("/updatecertificatesmanufacture", {title, desc, title_eng, desc_eng})
export const createCertificate = ({title, imgURL, desc, title_eng, desc_eng}) => http.post("/createcertificatesmanufacture", {title, imgURL, desc, title_eng, desc_eng})
export const getBottomManufactureData = () => http.get("/bottommanufacture")
export const updateBottomManufactureData = ({title, img, buttonLink, buttonTitle, title_eng, buttonLink_eng, buttonTitle_eng, id}) => http.patch("/updatebottommanufacture", {title, img, buttonLink, buttonTitle, title_eng, buttonLink_eng, buttonTitle_eng, id})


//admin I+D alliances routes
export const getBannerAlliances = () => http.get("/banneralliances")
export const updateBannerAlliances = ({title, description, imgURL, title_eng, description_eng, id}) => http.patch("/updatebanneralliances", {title, description, imgURL, title_eng, description_eng, id})
export const getLogoCarouselData = () => http.get("/alliancelogos")
export const updateTitleCarrouselAlliance = ({id, title, title_eng}) => http.patch("/updatealliancelogostitle", {id, title, title_eng})
export const deleteLogoCarouselAlliance = (id) => http.get(`/alliancelogos/${id}/delete`)
export const createAlly = ({title, picPath, title_eng}) => http.post("/createalliance", {title, picPath, title_eng})
export const getFormAlliances = () => http.get("/allianceform")
export const allianceForm = ({name, lastname, mail, phone, country, company, message}) => http.post("/addallianceform", {name, lastname, mail, phone, country, company, message})
export const updateFormAlliances = ({title, desc, phone, email, title_eng, desc_eng, phone_eng, email_eng, id}) => http.post("/updateallianceform", {title, desc, phone, email, title_eng, desc_eng, phone_eng, email_eng, id})
export const getBottomAlliancesData = () => http.get("/bottomalliances")
export const updateBottomAlliancesData = ({title, img, buttonLink, buttonTitle, title_eng, buttonLink_eng, buttonTitle_eng, id}) => http.patch("/updatebottomalliances", {title, img, buttonLink, buttonTitle, title_eng, buttonLink_eng, buttonTitle_eng, id})
export const getLeadsFormData = () => http.get("/leadsform")
export const deleteLeadsCard = (id) => http.get(`/leadsform/${id}/delete`)


//admin edit routes purpose and responsability
export const getBannerPurpose = () => http.get("/bannerdatapurpose")
export const updateBannerDataPurpose = ({description, imgURL, description_eng, id}) => http.patch("/updatebannerdatapurpose", {description, imgURL, description_eng, id})
export const getPurposeVideo = () => http.get("/purposevideo")
export const updatePurposeVideo = (videoURL, id) => http.patch("/updatepurposevideo", {videoURL, id})
export const getTimeLinePurpose = () => http.get("/timelinepurpose")
export const updateTimeLinePurpose = ({desc, imgURL, desc_eng, id}) => http.patch("/updatetimelinepurpose", {desc, imgURL, desc_eng, id})
export const deleteTimelinePurpose = (id) => http.get(`/deletetimelinepurpose/${id}`)
export const addTimeLinePurposeData = ({desc, imgURL, desc_eng}) => http.post("/addtimelinepurpose", {desc, imgURL, desc_eng})
export const updateTitleFarmDataPurpose = ({title, title_eng, id}) => http.patch("/updatetitlefarmdatapurpose", {title, title_eng, id})

//admin edit routes our people
export const getBannerOurPeople = () => http.get("/bannerdataourpeople")
export const updateBannerOurPeople = ({description, imgURL, title, description_eng, title_eng, id}) => http.patch("/updatebannerdataourpeople", {description, imgURL, title, description_eng, title_eng, id})
export const getInfoCardsOurPeople = () => http.get("/ourpeopleinfocards")
export const updateInfoCardsOurPeople = ({mainTitle, imgURL, title, info, mainTitle_eng, title_eng, info_eng, id}) => http.patch("/updateourpeopleinfocards", {mainTitle, imgURL, title, info, mainTitle_eng, title_eng, info_eng, id})
export const getEquipoOurPeople = () => http.get("/equipoourpeople")
export const updateEquipoOurPeople = ({title, description, person, imgURL, buttonTitle, buttonLink, title_eng, description_eng, person_eng, buttonTitle_eng, buttonLink_eng, id}) => http.patch("/updateourpeopleequipo", {title, description, person, imgURL, buttonTitle, buttonLink, title_eng, description_eng, person_eng, buttonTitle_eng, buttonLink_eng, id})
export const getBottomOurPeople = () => http.get("/bottomourpeople")
export const updateBottomOurPeople = ({title, buttonLink, buttonTitle, img, title_eng, buttonLink_eng, buttonTitle_eng, id}) => http.patch("/updatebottomourpeople", {title, buttonLink, buttonTitle, img, title_eng, buttonLink_eng, buttonTitle_eng, id})
export const getCarreras = () => http.get("/carrerasdata")
export const getEtica = () => http.get("/ethicsdata")
export const updateCarrerasData = ({title, description, url, buttonTitle, buttonLink, title_eng, description_eng, url_eng, buttonTitle_eng, buttonLink_eng, id}) => http.patch("/updatecarrerasdata", {title, description, url, buttonTitle, buttonLink, title_eng, description_eng, url_eng, buttonTitle_eng, buttonLink_eng, id})
export const updateEthicsData = ({title, description, url, buttonTitle, title_eng, description_eng, buttonTitle_eng, id}) => http.patch("/updateethicsdata", {title, description, url, buttonTitle, title_eng, description_eng, buttonTitle_eng, id})
export const deleteOPInfoCard = (id) => http.get(`/ourpeopleinfocard/${id}/delete`)
export const createTeam = ({title, info, title_eng, info_eng}) => http.post("/createteam", {title, info, title_eng, info_eng})
export const getBannerTeams = () => http.get("/bannerdatateams")
export const updateBannerTeams = ({mainTitle, imgURL, mainTitle_eng, id}) => http.patch("/updatebannerdatateams", {mainTitle, imgURL, mainTitle_eng, id})
export const getInfoBannerOurPeople = () => http.get('/getibourpeople')
export const getBannerBtm = () => http.get('/getbannerbtm')
export const updateBannerBtm = ({description, imgURL, description_eng, id}) => http.patch('/updatebannerbtm', {description, imgURL, description_eng, id})
export const updateInfoBannerOurPeople = ({mainDescription, backgroundURL, item, mainDescription_eng, item_eng, id}) => http.patch("/updateinfobannerdataourpeople", {mainDescription, backgroundURL, item, mainDescription_eng, item_eng, id})
export const updateInfoBannerOurPeopleDet = ({whole, desc, iconURL, number, whole_eng, desc_eng, number_eng, id}) => http.patch("/updateinfobannerdataourpeopledet", {whole, desc, iconURL, number, whole_eng, desc_eng, number_eng, id})

//admin form work with us routes
export const deleteWorkWithUsCard = (id) => http.get(`/workwithus/${id}/delete`)


//admin nav routes 
export const getNav = () => http.get("/getnavdata")
export const updateNavData = ({title, desc, nav_btn, nav_cta, title_eng, desc_eng, nav_btn_eng, nav_cta_eng, id}) => http.patch("/updatenavdata", {title, desc, nav_btn, nav_cta, title_eng, desc_eng, nav_btn_eng, nav_cta_eng, id})

//search routes
export const searchContent = (search) => http.get(`/search/${search}`)
export const searchNews = (data) => http.post(`/buscarnoticia`, {data})

//product routes    
export const getProduct = (buscar, pathname) => http.post("/producstdata", {buscar, pathname})
export const getProductList = () => http.get("/listado-productos")
export const deleteProduct = (id) => http.get(`/producto/${id}/delete`)
export const updateProduct = ({id, name, picPath, QRpath, line, subLine, composition, health_register, active_principle, posology, presentation, indication, therapeutic_group, category, util_life, cpe, how_to_use, contraindications, adverse_reactions, prospect, show_in_products, supplierFarmatodoLink, supplierLocatelLink, supplierFarmadonLink, supplierFarmabienLink, supplierNuevosigloLink, url}) => http.patch(`/producto/${id}/update`, {name, picPath, QRpath, line, subLine, composition, health_register, active_principle, posology, presentation, indication, therapeutic_group, category, util_life, cpe, how_to_use, contraindications, adverse_reactions, prospect, show_in_products, supplierFarmatodoLink, supplierLocatelLink, supplierFarmadonLink, supplierFarmabienLink, supplierNuevosigloLink, url})
export const addProductApi = ({name, picPath, QRpath, line, subLine, composition, health_register, active_principle, posology, presentation, indication, therapeutic_group, category, util_life, cpe, how_to_use, contraindications, adverse_reactions, prospect, show_in_products, supplierFarmatodoLink, supplierLocatelLink, supplierFarmadonLink, supplierFarmabienLink, supplierNuevosigloLink, url}) => http.post("/crear-producto", {name, picPath, QRpath, line, subLine, composition, health_register, active_principle, posology, presentation, indication, therapeutic_group, category, util_life, cpe, how_to_use, contraindications, adverse_reactions, prospect, show_in_products, supplierFarmatodoLink, supplierLocatelLink, supplierFarmadonLink, supplierFarmabienLink, supplierNuevosigloLink, url})
export const getProductBanner = () => http.get("/bannerproduct")
export const updateProductBanner = ({description, imgURL, title, button1Title, button1Link, button2Title, button2Link, description_eng, title_eng, button1Title_eng, button1Link_eng, button2Title_eng, button2Link_eng, id}) => http.patch("/updatebannerproducts", {description, imgURL, title, button1Title, button1Link, button2Title, button2Link, description_eng, title_eng, button1Title_eng, button1Link_eng, button2Title_eng, button2Link_eng, id})
export const getProductBottom = () => http.get("/bottomproduct")
export const updateProductBottom = ({findProductsTitle, imgURL, title, buttonTitle, farmacoTitle, farmacoBtn, farmacoDesc, findProductsTitle_eng, title_eng, buttonTitle_eng, farmacoTitle_eng, farmacoBtn_eng, farmacoDesc_eng, id}) => http.patch("/updateproductbottom", {findProductsTitle, imgURL, title, buttonTitle, farmacoTitle, farmacoBtn, farmacoDesc, findProductsTitle_eng, title_eng, buttonTitle_eng, farmacoTitle_eng, farmacoBtn_eng, farmacoDesc_eng, id})
export const getProductListBanner = () => http.get("/listadoproductosbanner")
export const updateProductListBanner = ({description, imgURL, title, description_eng, title_eng, id}) => http.patch("/updatelistadoproductosbanner", {description, imgURL, title, description_eng, title_eng, id})
export const productInfoForm = ({name, lastname, work, years, speciality, info, license, mail, name_eng, lastname_eng, work_eng, years_eng, speciality_eng, info_eng, license_eng, mail_eng}) => http.post("/productinfoform", {name, lastname, work, years, speciality, info, license, mail, name_eng, lastname_eng, work_eng, years_eng, speciality_eng, info_eng, license_eng, mail_eng})
export const getProductInfoForm = () => http.get("/productinfoformdata")
export const deleteProductInfoCard = (id) => http.get(`/productinfoform/${id}/delete`)
export const getProductProspect = (id, pathname) => http.post(`/prospect/${id}`, {pathname})
export const getProductDataFromActivePrinciple = (id, pathname) => http.post(`/product/${id}`, {pathname})

export const getProductDataSheet = (id, pathname) => http.post(`/ficha-tecnica/${id}`, {pathname})
export const getLines = () => http.get("/linesdata")
export const getSubLines = () => http.get("/sublinesdata")
export const createLine = ({line, line_eng}) => http.post("/createline", {line, line_eng})
export const createSubLine = ({subLine, subLine_eng}) => http.post("/createsubline", {subLine, subLine_eng})
export const deleteLine = (id) => http.get(`/line/${id}/delete`)
export const deleteSubLine = (id) => http.get(`/subline/${id}/delete`)
export const getSuppliers = () => http.get("/suppliers")
export const updateBannerSuppliers = ({title, title_eng, imgURLFarmatodo, imgURLLocatel, imgURLFarmadon, imgURLFarmabien, imgURLNuevosiglo, URLFarmatodo, URLLocatel, URLFarmadon, URLFarmabien, URLNuevosiglo, id}) => http.patch("/updatesuppliers", {title, title_eng, imgURLFarmatodo, imgURLLocatel, URLFarmadon, URLFarmabien, URLNuevosiglo, imgURLFarmadon, imgURLFarmabien, imgURLNuevosiglo, URLFarmatodo, URLLocatel, id})

// our philosophy page
export const getBannerOurPhilosophy = () => http.get("/bannerourphilosophy")
export const updateBannerOurPhilosophy = ({description, imgURL, title, description_eng, title_eng, id}) => http.patch("/updatebannerourphilosophy", {description, imgURL, title, description_eng, title_eng, id})
export const getInfoCardsOurPhilosophy = () => http.get("/ourphilosophyinfocards")
export const updateOPPillar = ({picPath, title, title_eng, id}) => http.patch("/updateourphilosophyinfocards", {picPath, title, title_eng, id})
export const deleteOPPillar = (id) => http.get(`/ourphilosophyinfocards/${id}/delete`)
export const createPillar = ({picPath, title, title_eng}) => http.post("/createpillar", {picPath, title, title_eng})
export const getLetterOurPhilosophy = () => http.get("/ourphilosophyletter")
export const updateLetterOurPhilosophy = ({body, imgURL, mainTitle, body_eng, mainTitle_eng, id}) => http.patch("/updateourphilosophyletter", {body, imgURL, mainTitle, body_eng, mainTitle_eng, id})
export const getBottomOurPhilosophy = () => http.get("/bottomourphilosophy")
export const updateBottomOurPhilosophy = ({title, imgURL, description, buttonLink, buttonTitle, title_eng, description_eng, buttonLink_eng, buttonTitle_eng, id}) => http.patch("/updatebottomourphilosophy", {title, imgURL, description, buttonLink, buttonTitle, title_eng, description_eng, buttonLink_eng, buttonTitle_eng, id})


//therapeutic areas page
export const getBannerTA = () => http.get("/bannerta")
export const updateBannerTA = ({title, description, imgURL, title_eng, description_eng, id}) => http.patch("/updatebannerta", {title, description, imgURL, title_eng, description_eng, id})
export const getCarrouselTA = () => http.get("/carrouselta")
export const updateCarrouselTA = ({title, mainTitle, desc, imgURL, title_eng, mainTitle_eng, desc_eng, id}) => http.patch("/updatecarrouselta", {title, mainTitle, desc, imgURL, title_eng, mainTitle_eng, desc_eng, id})
export const createCarrouselTA = ({title, mainTitle, desc, imgURL, title_eng, mainTitle_eng, desc_eng}) => http.post("/createcarrouselta", {title, mainTitle, desc, imgURL, title_eng, mainTitle_eng, desc_eng})
export const deleteTaCarrItem = (id) => http.get(`/carrouselta/${id}/delete`)
export const updateTAGalleryTitle = ({mainTitle, mainTitle_eng}) => http.post("/updatetagallerytitle", {mainTitle})
export const getBottomTA = () => http.get("/bottomta")
export const updateBottomTA = ({title, buttonLink, buttonTitle, img, title_eng, buttonLink_eng, buttonTitle_eng, id}) => http.patch("/updatebottomta", {title, buttonLink, buttonTitle, img, title_eng, buttonLink_eng, buttonTitle_eng, id})

//news routes
export const getNews = () => http.get("/newsdata")
export const getTags = () => http.get("/tagsdata")
export const deleteTag = (id) => http.get(`/tag/${id}/delete`)
export const createTag = ({tag, tag_eng}) => http.post("/createtag", {tag, tag_eng})
export const getNewsTitles = () => http.get("/newstitles")
export const updateNewsTitles = ({lastestTitle, mostTitle, searchTitle, picPath, id}) => http.patch("/updatenewstitles", {lastestTitle, mostTitle, searchTitle, picPath, id})
export const addNewsApi = ({title, subTitle, urlToPic, tag, content, outstanding, publishDate}) => http.post("/createnews", {title, subTitle, urlToPic, tag, content, outstanding, publishDate})
export const getRandomNews = (category) => http.post("/getrandomnews", {category})
export const getLinkedNews = (id) => http.get(`/noticia/${id}`)
export const addOutstandingNews = (outstanding, id) => http.post(`/addoutstandingnews/${id}`, {outstanding, id})
export const deleteNews = (id) => http.get(`/news/${id}/delete`)
export const updateNews = ({title, subTitle, urlToPic, tag, content, outstanding, publishDate, id}) => http.patch(`/updatenews/${id}`, {title, subTitle, urlToPic, tag, content, outstanding, publishDate, id})


// gente leti routes
export const workWithUsForm = ({name, lastname, email, phone, country, city, cv, linkedin}) => http.post("/workwithus", {name, lastname, email, phone, country, city, cv, linkedin})
export const getWorkWithUsInfo = () => http.get("/workwithusinfodata")
export const sendEmailForm = ({name, lastname, email, phone, country, city, cv, linkedin}) => http.post("/sendemailform", {name, lastname, email, phone, country, city, cv, linkedin})
export const getPixelData = () => http.get("/pixels")
export const updatePixels = ({facebook1, facebook2, google, google2, id}) => http.patch("/updatepixels", {facebook1, facebook2, google, google2, id})

//seo routes
export const getSeo = () => http.get("/seodata")
export const updateSeo = ({keywords, description, page, keywords_eng, description_eng, page_eng, id}) => http.patch("/updateseo", {keywords, description, page, keywords_eng, description_eng, page_eng, id})

// //landingpage bioletisan routes
export const getBannerBioletisan = () => http.get("/getbioletisanbanner")
export const getGoalsBioletisan = () => http.get("/getbioletisangoals")
export const getInfoBioletisan = () => http.get("/getbioletisaninfo")
export const getTechBioletisan = () => http.get("/getbioletisantech")
export const getPlaceBioletisan = () => http.get("/getbioletisanplaces")
export const getFaq = () => http.get("/getfaq")
export const getFaqItems = () => http.get("/getfaqitems")
export const getNewsBioletisan = () => http.get("/getnewsbioletisan")
export const getNewsItems = () => http.get("/getbioletisannewsitems")
export const getSocialBioletisan = () => http.get("/getsocialbioletisan")
export const updateBioletisanBannerData = ({title, title_eng, imgURLOne, imgURLTwo, imgURLThree, id}) => http.patch("/updatebioletisanbanner", {title, title_eng, imgURLOne, imgURLTwo, imgURLThree, id})
export const updateGoalData = ({title, description, title_eng, description_eng, icon_url, id}) => http.patch("/updategoaldata", {title, description, title_eng, description_eng, icon_url, id})
export const deleteGoalItem = (id) => http.get(`/deletegoalitem/${id}`)
export const updateBioletisanGoalsTitle = ({id, title, title_eng}) => http.patch("/updatebioletisangoalstitle", {id, title, title_eng})
export const addGoalItem = ({title, description, title_eng, description_eng, icon_url}) => http.post("/createbioletisangoal", {title, description, title_eng, description_eng, icon_url})
export const updateInfoData = ({title, item_description, title_eng, item_description_eng, item_tag, item_tag_eng, icon_url, id}) => http.patch("/updateinfodata", {title, item_description, title_eng, item_description_eng, item_tag, item_tag_eng, icon_url, id})
export const deleteInfoItem = (id) => http.get(`/deleteinfoitem/${id}`)
export const updateBioletisanInfoTitle = ({id, title, title_eng}) => http.patch("/updatebioletisaninfotitle", {id, title, title_eng})
export const updateBioletisanInfoSubTitle = ({id, subtitle, subtitle_eng}) => http.patch("/updatebioletisaninfosubtitle", {id, subtitle, subtitle_eng})
export const addInfoItem = ({title, title_eng, subtitle, subtitle_eng, icon_url, item_tag, item_tag_eng, item_description, item_description_eng}) => http.post("/createbioletisaninfo", {title, title_eng, subtitle, subtitle_eng, icon_url, item_tag, item_tag_eng, item_description, item_description_eng})
export const updateBioletisanTechData = ({link, link_eng, link_url, bg_url, description, description_eng, id}) => http.patch("/updatebioletisantechinfo", {link, link_eng, link_url, bg_url, description, description_eng, id})
export const updateSupplierItem = ({place_url, place_link, id}) => http.patch("/updatesupplieritem", {place_url, place_link, id})
export const deleteSupplierItem = (id) => http.get(`/deletesupplieritem/${id}`)
export const updateFAQInfo = ({title, title_eng, description, description_eng, link, link_eng, link_url, id}) => http.patch("/updatefaqinfo", {title, title_eng, description, description_eng, link, link_eng, link_url, id})
export const updateFaqItem = ({title, title_eng, description, description_eng,  id}) => http.patch("/updatefaqitem", {title, title_eng, description, description_eng,  id})
export const deleteFaqItem = (id) => http.get(`/deletefaqitem/${id}`)
export const addFaqItem = ({title, title_eng, description, description_eng}) => http.post("/createfaqitem", {title, title_eng, description, description_eng})
export const updateBannerRrssData = ({title, title_eng, description, description_eng, background, id}) => http.patch("/updatebannerrrssdata", {title, title_eng, description, description_eng, background, id})


//landingpage biocontrolled routes
export const getWhoWeAre = () => http.get("/getwhoweare")
export const getMarquee = () => http.get("/getmarquee")
export const getModified = () => http.get("/getmodified")
export const getTechTitle = () => http.get("/gettechtitle")
export const getTechCards = () => http.get("/gettechcards")
export const getClinical = () => http.get("/getclinical")
export const getFooter = () => http.get("/getfooter")

export const updateBiocontrolledWhoWeAreData = ({title, title_eng, description, description_eng, id}) => http.patch("/updatebiocontrolledwhoweare", {title, title_eng, description, description_eng, id})
export const updateBiocontrolledMarqueeData = ({text, text_eng, id}) => http.patch("/updatebiocontrolledmarquee", {text, text_eng, id})
export const updateBiocontrolledModifiedData = ({title_one, title_one_eng, title_two, title_two_eng, title_three, title_three_eng, description, description_eng, description_two, description_two_eng, id}) => http.patch("/updatebiocontrolledmodified", {title_one, title_one_eng, title_two, title_two_eng, title_three, title_three_eng, description, description_eng, description_two, description_two_eng, id})
export const updateBiocontrolledtechTitleData = ({title, title_eng, description, description_eng, id}) => http.patch("/updatebiocontrolledtechtitle", {title, title_eng, description, description_eng, id})
export const updateTechData = ({title, title_eng, link, description, description_eng, id}) => http.patch("/updatebiocontrolledtech", {title, title_eng, link, description, description_eng, id})
export const updateBiocontrolledClinicalData = ({title, title_eng, description, description_eng, info, info_eng, title_two, title_two_eng, description_two, description_two_eng, id}) => http.patch("/updatebiocontrolledclinical", {title, title_eng, description, description_eng, info, info_eng, title_two, title_two_eng, description_two, description_two_eng, id})
export const addTechItem = ({title, title_eng, description, description_eng, link}) => http.post("/createbiocontrolledtech", {title, title_eng, description, description_eng, link})
export const deleteTechItemElem = (id) => http.get(`/deletebiocontrolledtech/${id}`)
export const updateFooterData = ({title, title_eng, description, description_eng, id}) => http.patch("/updatebiocontrolledfooter", {title, title_eng, description, description_eng, id})
export const deleterFooterItem = (id) => http.get(`/deletebiocontrolledfooter/${id}`)

//landingpage farmacovigilancia rputes
export const getBannerFarmacovigilancia = () => http.get("/getbannerfarmacovigilancia")
export const getFormFarmacovigilancia = () => http.get("/getformfarmacovigilancia")
export const getOurCommitment = () => http.get("/getourcommitment")
export const getRolling = () => http.get("/getrolling")
export const getBannerTwo = () => http.get("/getbannertwo")
export const getInfoTwo = () => http.get("/getinfotwo")
export const getBannerThree = () => http.get("/getbannerthree")
export const getFarmacovigilanciaFaq = () => http.get("/getfarmacovigilanciafaq")
export const farmacoVigilanciaForm = ({name, phone, medication, email}) => http.post("/farmacovigilanciaform", {name, phone, medication, email})
export const updateFarmacovigilanciaBannerData = ({title, title_eng, description, description_eng, bgURL, id}) => http.patch("/updatefarmacovigilanciabanner", {title, title_eng, description, description_eng, bgURL, id})
export const updateRollData = ({title, title_eng, info, info_eng, icon, iconred, id}) => http.patch("/updaterolldata", {title, title_eng, info, info_eng, icon, iconred, id})
export const updateRollingInfo = ({title, title_eng, description, description_eng, id}) => http.patch("/updaterollinginfo", {title, title_eng, description, description_eng, id})
export const addRollingItem = ({title, title_eng, info, info_eng, icon, iconred}) => http.post("/createrollingitem", {title, title_eng, info, info_eng, icon, iconred})
export const deleteRollItem = (id) => http.get(`/deleterollitem/${id}`)
export const updateBannerTwoData = ({title, title_eng, tag, tag_eng, bgURL, tagURL, id}) => http.patch("/updatebannertwodata", {title, title_eng, tag, tag_eng, bgURL, tagURL, id})
export const updateInfoTwo = ({title, title_eng, description, description_eng, id}) => http.patch("/updateinfotwo", {title, title_eng, description, description_eng, id})
export const updateBannerThreeData = ({title, title_eng, tag, tag_eng, bgURL, tagURL, bgTwoURL, id}) => http.patch("/updatebannerthreedata", {title, title_eng, tag, tag_eng, bgURL, tagURL, bgTwoURL, id})
export const deleteFVFaqItem = (id) => http.get(`/deletefvfaqitem/${id}`)
export const addFVFaqItem = ({title, title_eng, info, info_eng}) => http.post("/createfvfaqitem", {title, title_eng, info, info_eng})
export const updateFVFaqItem = ({title, title_eng, info, info_eng, id}) => http.patch("/updatefvfaqitem", {title, title_eng, info, info_eng, id})
export const updateFormTexts = ({title, title_eng, description, description_eng, id}) => http.patch("/updateformtexts", {title, title_eng, description, description_eng, id})