import React, {useState, useEffect} from 'react'

import {getApiVideo, getApiHomeBanner} from '../../../../services/ApiClient'
import Loader from '../../../../components/Loader/Loader'
import './Video.css'
import {useAuthContext} from '../../../../contexts/AuthContext'

function Video() {

    const [videoData, setVideoData] = useState()
    const [videoDataEng, setVideoDataEng] = useState()
    const [loader, setLoader] = useState(true)
    const {language} = useAuthContext()
    const [homeBannerData, sethomeBannerData] = useState()

    useEffect(() => {
        const fetchData = async () => {
            const getVideoData = await getApiVideo()
            setVideoData(getVideoData[0]?.url)
            setVideoDataEng(getVideoData[0]?.url_eng)

            const getHomeBannerData = await getApiHomeBanner()
            sethomeBannerData(getHomeBannerData?.url)
        }
        fetchData()
        setLoader(!loader)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loader && <Loader />}
            {!homeBannerData &&
                <section className="container-fluid Video">
                    {(videoData && language === 'ES') && (
                        <video poster="https://firebasestorage.googleapis.com/v0/b/grupo-leti-fd84e.appspot.com/o/images%2Fvideo-hero.jpg?alt=media&token=ac7994ed-0ede-4447-a252-94981fec24b5" loop muted autoPlay playsInline>
                            <source src={videoData} type="video/mp4" />
                        </video>
                    )}
                    {(videoDataEng && language === 'EN') && (
                        <video poster="https://firebasestorage.googleapis.com/v0/b/grupo-leti-fd84e.appspot.com/o/images%2Fvideo-hero.jpg?alt=media&token=ac7994ed-0ede-4447-a252-94981fec24b5" loop muted autoPlay playsInline>
                            <source src={videoDataEng} type="video/mp4" />
                        </video>
                    )}
                </section>
            }
        </>
    )
}



export default Video
