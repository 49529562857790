import React, {useState, useEffect} from 'react'
import {Fade} from "react-awesome-reveal"
import {useAuthContext} from '../../../../contexts/AuthContext'
import {getInfoBannerOurPeople} from '../../../../services/ApiClient'

import './InfoBanner.css'


function InfoBanner() {

    const [infoBannerData, setInfoBannerData] = useState([])
    const [items, setItems] = useState([])
    const {language} = useAuthContext()

    useEffect(() => {

        const fetchData = async () => {
            const getInfoBannerData = await getInfoBannerOurPeople()
            setInfoBannerData(getInfoBannerData[0])
            setItems(getInfoBannerData[0].item)

            const counters = document.querySelectorAll('.value')
            const speed = 200

            counters.forEach(counter => {
                const animate = () => {
                    const value = +counter.getAttribute('akhi')
                    const data = +counter.innerText

                    const time = value / speed
                    if (data < value) {
                        counter.innerText = Math.ceil(data + time)
                        setTimeout(animate, 1)
                    } else {
                        counter.innerText = value
                    }

                }

                animate()
            })
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [])

    return (
        <section className="container-fluid InfoBanner" style={{
            background: `url(${infoBannerData?.backgroundURL}) no-repeat center / cover`,
        }}>
            <div className="InfoBanner-data col-12">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-sm-8">
                            <div className="row d-flex justify-content-between">
                                <div className="co-12 col-sm-5">
                                    <div className="row">
                                        <Fade cascade duration={600} delay={300} triggerOnce>
                                            <div className="col-12 InfoBanner-colaboradores" data-speed="-.1" data-axis="vertical" style={{
                                                background: `url(${items[0]?.iconURL}) no-repeat left top / 36%`,
                                            }}>+<span className="value" akhi={items[0]?.number}>0</span> <span className="InfoBanner-colaboradores-texto">{language === 'ES' ? items[0]?.desc : items[0]?.desc_eng}</span></div>
                                            <div className="col-12 text-desc" data-speed=".05" data-axis="vertical" dangerouslySetInnerHTML={{__html: language === 'ES' ? infoBannerData?.mainDescription : infoBannerData?.mainDescription_eng}}/>
                                        </Fade>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-5 aside">
                                    <Fade duration={600} delay={300} triggerOnce>
                                        <div className="row">
                                            <div className="col-6 col-sm-12 text"><span className="value  InfoBanner-colaboradores InfoBanner-colaboradores-mujeres" akhi={items[1]?.number} style={{
                                                background: `url(${items[1]?.iconURL}) no-repeat center left / contain`,
                                            }}>0</span>% <span className="InfoBanner-colaboradores-texto">{language === 'ES' ? items[1]?.desc : items[1]?.desc_eng}</span></div>
                                            <div className="col-6 col-sm-12 custom-margin"><span className="value InfoBanner-colaboradores InfoBanner-colaboradores-hombres" akhi={items[2]?.number} style={{
                                                background: `url(${items[2]?.iconURL}) no-repeat center left / contain`,
                                            }}>0</span>% <span className="InfoBanner-colaboradores-texto">{language === 'ES' ? items[2]?.desc : items[2]?.desc_eng}</span></div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoBanner
