import './Timeline.css'
import React, {useState, useEffect} from 'react'
import Slider from "react-slick"
import {Link} from 'react-router-dom'
import {Fade} from 'react-awesome-reveal'
import {getTimeLineBiocontrolled} from '../../../../services/ApiClient'
import {useAuthContext} from '../../../../contexts/AuthContext'

function Timeline() {

    const [biocontrolledTimeline, setBiocontrolledTimeline] = useState()
    const {language} = useAuthContext()

    let settings = {
        slidesToShow: 1,
        speed: 500,
        arrows: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    useEffect(() => {

        const fetchData = async () => {
            const getTimeLineData = await getTimeLineBiocontrolled()
            setBiocontrolledTimeline(getTimeLineData)


            const getAllDesc = Array.from(new Set(document.querySelectorAll('.Timeline__biocontrolled__desc')))

            const getDescX = getAllDesc[0]?.getBoundingClientRect()?.x

            const getDescsMaxHeight = Math.max(...getAllDesc?.map(item => item?.offsetHeight))

            const getTimelineHeight = document.querySelector('.Timeline__biocontrolled__info')?.offsetHeight

            const PrevArrow = document.querySelector('.Timeline__biocontrolled .slick-prev')
            const NextArrow = document.querySelector('.Timeline__biocontrolled .slick-next')

            if (biocontrolledTimeline?.length > 1 && window.screen.width > 576) {
                //Arrows top position
                PrevArrow.style.top = `${((getTimelineHeight - getDescsMaxHeight) / 10) - 12}rem`
                NextArrow.style.top = `${((getTimelineHeight - getDescsMaxHeight) / 10) - 12}rem`

                //Prev arrows left position
                PrevArrow.style.left = `${(-getDescX / 10) + 4}rem`
                //Next arrows right position
                NextArrow.style.left = `${(-getDescX / 10) + 14}rem`
            }
        }
        fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [biocontrolledTimeline?.length])

    return (
        <Fade direction="up" triggerOnce>
            <section className="container-fluid Timeline__biocontrolled">
                <Slider {...settings}>
                    {biocontrolledTimeline?.map(el =>
                        <>
                            <div className="Timeline__biocontrolled__product row">
                                <div className="Timeline__biocontrolled__image col-12 col-sm-6" style={{
                                    background: `url(${el?.imgURL}) no-repeat left center / cover`
                                }}></div>
                                <div className="col-12 col-sm-6 Timeline__biocontrolled__info">
                                    <div className="row">
                                        <p className="col-12 Timeline__biocontrolled__desc" dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.desc : el?.desc_eng}}>
                                        </p>
                                    </div>
                                    {el?.buttonTitle &&
                                        <div className="row Timeline__biocontrolled__btn">
                                            <div className="col-11 col-sm-5">
                                                {el?.buttonLink.includes('http') ? 
                                                <Link to={{pathname: `${language === 'ES' ? el?.buttonLink : el?.buttonLink_eng}`}} target="_blank" className="leti-btn">{language === 'ES' ? el?.buttonTitle : el?.buttonTitle_eng}</Link> :
                                                <Link to={language === 'ES' ? el?.buttonLink : el?.buttonLink_eng} className="leti-btn">{language === 'ES' ? el?.buttonTitle : el?.buttonTitle_eng}</Link>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    )}
                </Slider>
            </section>
        </Fade>
    )
}

export default Timeline