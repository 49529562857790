import React, {useState} from 'react'
import {Helmet} from 'react-helmet'

import AdminBioletisanLandingPage from '../AdminBioletisanLandingPage/AdminBioletisanLandingPage'
import AdminBiocontrolledLandingPage from '../AdminBiocontrolledLandingPage/AdminBiocontrolledLandingPage'
import AdminFarmacoVigilanciaLandingPage from '../AdminFarmacoVigilanciaLandingPage/AdminFarmacoVigilanciaLandingPage'
import './AdminLandingPages.css'
import Nav from './Nav/Nav'


function AdminLandingPages() {


    const [initNavValue, setInitNavValue] = useState('bioletisan')

    const getActiveNav = (activeNav) => {
        setInitNavValue(activeNav)
    }

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Landing Pages</title>
            </Helmet>
            <main className="container-fluid AdminEditPage">
                <div className="row">
                    <div className="col-2 p-0 AdminEditPage__NavContent">
                        <Nav getActiveItem={getActiveNav} />
                    </div>
                    <div className="col-10">
                        {initNavValue === 'bioletisan' && <AdminBioletisanLandingPage />}
                        {initNavValue === 'biocontrolled' && <AdminBiocontrolledLandingPage />}
                        {/* {initNavValue === 'farmacovigilancia' && <AdminFarmacoVigilanciaLandingPage />} */}
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminLandingPages