import './Banner.css'
import React, {useState, useEffect} from 'react'

import {getApiHomeBanner} from '../../../../services/ApiClient'
import Loader from '../../../../components/Loader/Loader'

function Banner() {

    const [homeBannerData, sethomeBannerData] = useState()
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            const getHomeBannerData = await getApiHomeBanner()
            sethomeBannerData(getHomeBannerData?.url)
        }
        fetchData()
        setLoader(!loader)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loader && <Loader />}
            {homeBannerData &&
                <section className="container-fluis Banner">
                    <div className="row">
                        <div className="col-12 col-sm-6 Banner__image" style={{background: `url(${homeBannerData}) no-repeat center / cover`}} />
                    </div>
                </section>
            }
        </>

    )
}

export default Banner
