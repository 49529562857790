
import './Carousel.css'
import React, {useState, useEffect} from 'react'
import Slider from "react-slick"
import {Fade} from 'react-awesome-reveal'
import {getCarrouselBiocontrolled} from '../../../../services/ApiClient'
import Loader from '../../../Loader/Loader'
import {useAuthContext} from '../../../../contexts/AuthContext'

function Carousel() {

    const [biocontrolledCarousel, setBiocontrolledCarousel] = useState([])
    const [loading, setLoading] = useState(true)
    const {language} = useAuthContext()

    let settings = {
        infinite: true,
        centerPadding: "40px",
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        dots: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    arrows: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    useEffect(() => {
        const fetchData = async () => {
            const getCarrouselData = await getCarrouselBiocontrolled()
            setBiocontrolledCarousel(getCarrouselData)
        }
        fetchData()
        setLoading(!loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading && <Loader />}
            <section className="Carousel__Biocontrolled">
                <div className="container">
                    <Fade direction="down" triggerOnce>
                        <h1 dangerouslySetInnerHTML={{__html: language === 'ES' ? biocontrolledCarousel[0]?.title : biocontrolledCarousel[0]?.title_eng}} />
                    </Fade>
                </div>
                <Fade triggerOnce direction="up">
                    <div className="container-fluid p-0">
                        <Slider {...settings}>
                            {console.log(biocontrolledCarousel)}
                            {biocontrolledCarousel
                                .slice()
                                .sort((a, b) => {
                                    const getYear = (info) => {
                                        const cleanText = info.replace(/<\/?[^>]+(>|$)/g, "")
                                        const match = cleanText.match(/Año (\d{4})/i) || cleanText.match(/(\d{4})/)
                                        return match ? parseInt(match[1]) : 0
                                    }
                                    return getYear(a.info) - getYear(b.info)
                                })
                                .map(el => (
                                    <div key={el._id}>
                                        <h3
                                            dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.info : el?.info_eng}}
                                            className="col-8 col-sm-4 Carousel__Biocontrolled__desc"
                                        />
                                    </div>
                                ))}
                        </Slider>
                    </div>
                </Fade>
            </section>
        </>
    )
}

export default Carousel
