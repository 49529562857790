import React, {useState} from 'react'
import {Editor} from '@tinymce/tinymce-react'

import {deleteProduct, updateProduct} from '../../../../services/ApiClient'
import {useFormState} from '../../../../hooks/useFormState'
import {app} from '../../../../services/firebase'

import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel'
import InputFile from '../../../Form/InputFile/InputFile'
import Button from '../../../Form/FormButton/FormButton'
import Loader from '../../../Loader/Loader'

import './ShowEditModal.css'
import DropdownWithLabel from '../../../Form/DropdownWithLabel/DropdownWithLabel'
import SwitchWithLabel from '../../../Form/SwitchWithLabel/SwitchWithLabel'
import {seoURLPA} from "../../../../hooks/seoURLPA"

function ShowEditModal({product, hideModal, updateData, lines, subLines}) {

    const [registerError, setRegisterError] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [checked, setChecked] = useState(true)

    const [fileSizeMessage, setFileSizeMessage] = useState('')
    const [firebaseUrl, setFirebaseUrl] = useState('')
    const [message, setMessage] = useState('')


    const {state, onChange} = useFormState(
        {
            data: {
                name: product?.name,
                picPath: product?.picPath,
                QRpath: product?.QRpath,
                line: product?.line,
                subLine: product?.subLine,
                composition: product?.composition,
                health_register: product?.health_register,
                active_principle: product?.active_principle,
                posology: product?.posology,
                presentation: product?.presentation,
                indication: product?.indication,
                therapeutic_group: product?.therapeutic_group,
                category: product?.category,
                prospect: product?.prospect,
                show_in_products: product?.show_in_products,
                name_eng: product?.name_eng,
                line_eng: product?.line_eng,
                composition_eng: product?.composition_eng,
                health_register_eng: product?.health_register_eng,
                active_principle_eng: product?.active_principle_eng,
                posology_eng: product?.posology_eng,
                presentation_eng: product?.presentation_eng,
                indication_eng: product?.indication_eng,
                therapeutic_group_eng: product?.therapeutic_group_eng,
                category_eng: product?.category_eng,
                prospect_eng: product?.prospect_eng,
                supplierFarmatodoLink: product.supplierFarmatodoLink,
                supplierLocatelLink: product.supplierLocatelLink,
                supplierFarmadonLink: product.supplierFarmadonLink,
                supplierFarmabienLink: product.supplierFarmabienLink,
                supplierNuevosigloLink: product.supplierNuevosigloLink,
                url: product?.url,
            },
            error: {
                name: true,
                picPath: true,
                QRpath: true,
                line: true,
                subLine: true,
                composition: true,
                health_register: true,
                active_principle: true,
                posology: true,
                presentation: true,
                indication: true,
                therapeutic_group: true,
                category: true,
                prospect: true,
                show_in_products: true,
                name_eng: true,
                line_eng: true,
                composition_eng: true,
                health_register_eng: true,
                active_principle_eng: true,
                posology_eng: true,
                presentation_eng: true,
                indication_eng: true,
                therapeutic_group_eng: true,
                category_eng: true,
                prospect_eng: true,
                supplierFarmatodoLink: true,
                supplierLocatelLink: true,
                supplierFarmadonLink: true,
                supplierFarmabienLink: true,
                supplierNuevosigloLink: true,
                url: true,
            },
            touch: {},
        },
        {
            name: v => v.length,
            picPath: v => v.length,
            QRpath: v => v.length,
            line: v => v.length,
            subLine: v => v.length,
            composition: v => v.length,
            health_register: v => v.length,
            active_principle: v => v.length,
            posology: v => v.length,
            presentation: v => v.length,
            indication: v => v.length,
            therapeutic_group: v => v.length,
            category: v => v.length,
            prospect: v => v.length,
            show_in_products: v => v.length,
            name_eng: v => v.length,
            line_eng: v => v.length,
            composition_eng: v => v.length,
            health_register_eng: v => v.length,
            active_principle_eng: v => v.length,
            posology_eng: v => v.length,
            presentation_eng: v => v.length,
            indication_eng: v => v.length,
            therapeutic_group_eng: v => v.length,
            category_eng: v => v.length,
            prospect_eng: v => v.length,
            supplierFarmatodoLink: v => v.length,
            supplierLocatelLink: v => v.length,
            supplierFarmadonLink: v => v.length,
            supplierFarmabienLink: v => v.length,
            supplierNuevosigloLink: v => v.length,
            url: v => v.length,
        }
    )

    const {data, error} = state

    const handleChange = (e) => {
        data[e.target.settings.name] = e.target.getContent()
        error[e.target.settings.name] = false
    }


    const updateThisProduct = async (event) => {
        event.preventDefault()
        data.id = product._id
        let pathname = ""
        if (data.subLine === "Dolor/SNC" || data.subLine === "Cardiometabólica") {
            pathname = `${seoURLPA(data.name)}-`
        }
        let active_principle_url = seoURLPA(data.active_principle)
        let genven = ""
        if (data.line === "Genven") {
            genven = "-genven"
        }
        const url = `${pathname}${active_principle_url}${genven}`
        data.url = url
        if (Object.values(error).map(el => el).includes(false)) {
            try {
                if (error.therapeutic_group === false) {
                    const setCategories = data.therapeutic_group.split(',')
                    data.therapeutic_group = setCategories
                }
                await updateProduct(data)
                    .then((info) => {
                        updateData(info)
                        setMessage('Data atualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    const deleteSelectedProduct = async (id) => {
        const updatedProductsData = await deleteProduct(id)
        updateData(updatedProductsData)
    }

    const onFileSelected = async (e) => {
        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')

            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    // console.log('Uploaded')
                })
                .catch(err => {setMessage(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data[e.target.name] = fileUrl
            setIsDisabled(false)
            error[e.target.name] = false
        }

    }

    const onFirebaseSelected = async (e) => {
        setFirebaseUrl('')

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    //console.log('Uploaded')
                })
                .catch(err => {setMessage(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()

            error[e.target.name] = false
            setFirebaseUrl(fileUrl)
            setIsDisabled(false)
        }
    }

    const setShow = (e) => {
        setChecked(!checked)
        error.show_in_products = false
        e.target.checked ? data.show_in_products = true : data.show_in_products = false
    }

    return (
        <>
            {isDisabled && <Loader message="Cargando imagen..." />}
            <main className="modal ShowEditModal ShowEditModal__addproduct">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-11 ShowEditModal__container product-modal">
                            <span className="ShowEditModal__close big" onClick={hideModal}></span>
                            <form className="AdminEdit__form" onSubmit={updateThisProduct}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h1 className="DeleteItemModal__ask">Editar {product.name}</h1>
                                    </div>
                                    <div className='col-12'>
                                        <SwitchWithLabel
                                            checked={data?.show_in_products}
                                            onChange={setShow}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <InputWithLabel
                                            label="Nombre"
                                            value={data?.name}
                                            onChange={onChange}
                                            name="name"
                                            type="text"
                                            cssStyle="form-control"
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <DropdownWithLabel
                                            placeholder={data?.line}
                                            value={data?.line}
                                            label="Línea"
                                            name="line"
                                            onChange={onChange}
                                            cssStyle="form-control"
                                            list="lines"
                                            data={[...new Set(lines.map(v => v.line))].sort()}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <DropdownWithLabel
                                            placeholder={data?.subLine}
                                            value={data?.subLine}
                                            label="SubLínea"
                                            name="subLine"
                                            onChange={onChange}
                                            cssStyle="form-control"
                                            list="subLines"
                                            data={[...new Set(subLines.map(v => v.subLine))].sort()}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <InputWithLabel
                                            label="Registro sanitario"
                                            value={data?.health_register}
                                            onChange={onChange}
                                            name="health_register"
                                            type="text"
                                            cssStyle="form-control"
                                            placeholder="Registro sanitario"
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <InputFile
                                            label="Imagen producto"
                                            value={data?.picPath}
                                            onChange={onFileSelected}
                                            id="fileButton"
                                            name="picPath"
                                            type="file"
                                        />
                                    </div>
                                    {/* <div className="col-12 col-sm-4">
                                            <InputFile
                                                label="QR producto"
                                                value={data?.QRpath}
                                                onChange={onFileSelected}
                                                id="fileButton"
                                                name="QRpath"
                                                type="file"
                                            />
                                        </div> */}
                                    <div className="col-4">
                                        <InputWithLabel
                                            label="Áreas terapéuticas / Separadas por comas"
                                            value={data?.therapeutic_group}
                                            onChange={onChange}
                                            name="therapeutic_group"
                                            type="text"
                                            cssStyle="form-control"
                                            placeholder="Áreas terapéuticas / Separadas por comas"
                                        />
                                    </div>
                                    {fileSizeMessage &&
                                        <div className="col-12">
                                            <small>{fileSizeMessage}</small>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-12 col-sm-4">
                                            <p className="label"><strong>Composición</strong></p>
                                            <Editor
                                                initialValue={data?.composition}
                                                onChange={handleChange}
                                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                init={{
                                                    name: 'composition',
                                                    height: 140,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image',
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount'
                                                    ],
                                                    toolbar:
                                                        'bold',
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <p className="label"><strong>Principio activo</strong></p>
                                            <Editor
                                                initialValue={data?.active_principle}
                                                onChange={handleChange}
                                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                init={{
                                                    name: 'active_principle',
                                                    height: 140,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image',
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount'
                                                    ],
                                                    toolbar:
                                                        'bold',
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <p className="label"><strong>Posología</strong></p>
                                            <Editor
                                                initialValue={data?.posology}
                                                onChange={handleChange}
                                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                init={{
                                                    name: 'posology',
                                                    height: 140,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image',
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount'
                                                    ],
                                                    toolbar:
                                                        'bold',
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <p className="label"><strong>Presentación</strong></p>
                                            <Editor
                                                initialValue={data?.presentation}
                                                onChange={handleChange}
                                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                init={{
                                                    name: 'presentation',
                                                    height: 140,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image',
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount'
                                                    ],
                                                    toolbar:
                                                        'bold',
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <p className="label"><strong>Indicaciones</strong></p>
                                            <Editor
                                                initialValue={data?.indication}
                                                onChange={handleChange}
                                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                init={{
                                                    name: 'indication',
                                                    height: 140,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image',
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount'
                                                    ],
                                                    toolbar:
                                                        'bold',
                                                }}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="label">Prospecto</p>
                                                <Editor
                                                    initialValue={data?.prospect}
                                                    onChange={handleChange}
                                                    apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                    init={{
                                                        name: 'prospect',
                                                        placeholder: "Ingresa texto del prospecto",
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar: 'undo redo | formatselect | ' +
                                                            'bold italic | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'table image | help',
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <InputFile
                                                    label="Imagen para insertar en prospecto"
                                                    value=""
                                                    onChange={onFirebaseSelected}
                                                    id="fileButton"
                                                    name="firebasePath"
                                                    type="file"
                                                />
                                                {firebaseUrl &&
                                                    <>
                                                        <small className="firebase-copy col-12">Copiar URL y pegar
                                                            en el campo de imagen del editor del
                                                            prospecto</small><br />
                                                        <small className="firebase col-12">{firebaseUrl}</small>
                                                    </>
                                                }
                                            </div>
                                            <div className='col-12 col-sm-3'>
                                                <InputWithLabel
                                                    label="URL del producto en Farmatodo"
                                                    value={data?.supplierFarmatodoLink}
                                                    onChange={onChange}
                                                    name="supplierFarmatodoLink"
                                                    type="text"
                                                    cssStyle="form-control"
                                                    placeholder="URL del producto en Farmatodo con prefijo http"
                                                />
                                            </div>
                                            <div className='col-12 col-sm-3'>
                                                <InputWithLabel
                                                    label="URL del producto en Locatel"
                                                    value={data?.supplierLocatelLink}
                                                    onChange={onChange}
                                                    name="supplierLocatelLink"
                                                    type="text"
                                                    cssStyle="form-control"
                                                    placeholder="URL del producto en Locatel con prefijo http"
                                                />
                                            </div>
                                            <div className='col-12 col-sm-3'>
                                                <InputWithLabel
                                                    label="URL del producto en Farmadon"
                                                    value={data?.supplierFarmadonLink}
                                                    onChange={onChange}
                                                    name="supplierFarmadonLink"
                                                    type="text"
                                                    cssStyle="form-control"
                                                    placeholder="URL del producto en Farmadon con prefijo http"
                                                />
                                            </div>
                                            <div className='col-12 col-sm-3'>
                                                <InputWithLabel
                                                    label="URL del producto en Farmabien"
                                                    value={data?.supplierFarmabienLink}
                                                    onChange={onChange}
                                                    name="supplierFarmabienLink"
                                                    type="text"
                                                    cssStyle="form-control"
                                                    placeholder="URL del producto en Farmabien con prefijo http"
                                                />
                                            </div>
                                            <div className='col-12 col-sm-3'>
                                                <InputWithLabel
                                                    label="URL del producto en Nuevo Siglo"
                                                    value={data?.supplierNuevosigloLink}
                                                    onChange={onChange}
                                                    name="supplierNuevosigloLink"
                                                    type="text"
                                                    cssStyle="form-control"
                                                    placeholder="URL del producto en Nuevo Siglo con prefijo http"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 mt-5">
                                        <Button type="submit" cssStyle="leti-btn">Guardar cambios</Button>
                                        {message && <span className="AdminEdit__message">{message}</span>}
                                    </div>
                                    <div className="col-12 col-sm-6 mt-5 d-flex justify-content-end">
                                        <div onClick={() => deleteSelectedProduct(product?._id)}
                                            className="leti-btn delete">Eliminar producto
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 mt-5">
                                        {registerError && <div className="alert alert-danger">{registerError}</div>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default ShowEditModal