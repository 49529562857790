import './WhoWeAre.css'
import React, {useState, useEffect} from 'react'
import {useAuthContext} from '../../../../../contexts/AuthContext'
import {getWhoWeAre} from '../../../../../services/ApiClient'
import {Fade} from 'react-awesome-reveal'

function WhoWeAre() {

    const [whoWeAreData, setWhoWeAreData] = useState([])
    const [splitTitle, setSplitTitle] = useState([])
    const [splitTitleEng, setSplitTitleEng] = useState([])
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getWhoWeAreData = await getWhoWeAre()
            setWhoWeAreData(getWhoWeAreData)
            setSplitTitle(getWhoWeAreData.title.split(' '))
            setSplitTitleEng(getWhoWeAreData.title_eng.split(' '))
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className="container-fluid BiocontrolledInfo-WhoWeAre">
                <div className='row justify-content-center'>
                    <div className='col-sm-6 col-12'>
                        <Fade>
                            <div className='BiocontrolledInfo-Logo' />
                        </Fade>
                        <Fade direction="left" triggerOnce>
                            <h1 className='mb-0'>{language === 'ES' ? splitTitle[0] : splitTitleEng[0]}</h1>
                        </Fade>
                        <Fade direction="right" triggerOnce>
                        <h1 className='mb-0'>{language === 'ES' ? splitTitle?.slice(1).join(" ") : splitTitleEng?.slice(1).join(" ")}</h1>
                        </Fade>
                        <Fade cascade damping={0.9} direction="up" triggerOnce>
                            <p dangerouslySetInnerHTML={{__html: language === 'ES' ? whoWeAreData?.description : whoWeAreData?.description_eng}} />
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhoWeAre
